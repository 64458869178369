import {
    USER_NOTICES_LIST_FETCHED,
    FETCHING_USER_NOTICES_LIST,
    REDRAW_USER_NOTICES_LIST,
    FETCHING_NOTICE_INFO,
    NOTICE_INFO_FETCHED,
    NOTICE_INFO_FAILED,
    SWITCH_NOTICE_INFO_DIALOG,
    PANEL_USER_NOTICES_FETCHED,
    SWITCH_ADD_NOTICE_DIALOG,
    NEW_NOTICE_ADDED,
    DELETING_NOTICE,
    NOTICE_DELETE_FAILED,
    NOTICE_DELETED,
    NEW_PANEL_USER_NOTICE,
    SEEN_USER_NOTICE,
    UPDATE_NOTICES_FILTER,
    FETCHING_SYSTEM_NOTICES_LIST,
    SYSTEM_NOTICES_LIST_FETCHED,
    NEW_SYSTEM_NOTICE_ADDED,
    SWITCH_ADD_SYSTEM_NOTICE_DIALOG,
    SWITCH_SYSTEM_NOTICE_INFO_DIALOG,
    SYSTEM_NOTICE_DELETED,
    SYSTEM_NOTICE_DELETE_FAILED,
    DELETING_SYSTEM_NOTICE,
    SYSTEM_NOTICE_INFO_FAILED,
    SYSTEM_NOTICE_INFO_FETCHED,
    FETCHING_SYSTEM_NOTICE_INFO,
    ADDING_NEW_SYSTEM_NOTICE,
    NEW_SYSTEM_NOTICE_ADD_FAILED,
    SWITCH_SYSTEM_NOTICE_EDIT_DIALOG,
    UPDATING_SYSTEM_NOTICE,
    SYSTEM_NOTICE_UPDATE_FAILED,
    SYSTEM_NOTICE_UPDATED,
    SWITCH_USER_NOTICE_EDIT_DIALOG,
    ADDING_NEW_NOTICE,
    NEW_NOTICE_ADD_FAILED,
    FETCHING_PHONE_MESSAGES_LIST,
    PHONE_MESSAGES_LIST_FETCHED,
    FETCHING_PHONE_MESSAGE,
    PHONE_MESSAGE_FETCHED,
    PHONE_MESSAGE_FAILED,
    UPDATE_PHONE_MESSAGES_FILTER,
    FETCHING_EMAIL_MESSAGES_LIST,
    EMAIL_MESSAGES_LIST_FETCHED,
    FETCHING_EMAIL_MESSAGE,
    EMAIL_MESSAGE_FETCHED, EMAIL_MESSAGE_FAILED, UPDATE_EMAIL_MESSAGES_FILTER,

} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    panelUserNotices: [],
    loadingPanelUserNotices: true,
    userNotices: [],
    systemNotices: [],
    totalUserNotices: 0,
    totalSystemNotices: 0,
    loadingUserNotices: false,
    loadingSystemNotices: false,
    fetchingUserNotice: false,
    fetchingSystemNotice: false,
    updatingUserNotices: false,
    authorizingUserNotices: false,
    selectedUserNotice: {},
    selectedSystemNotice: {},
    noticePayments: [],
    noticeUser: {},
    noticeInfoOpened: false,
    systemNoticeInfoOpened: false,
    noticeEditOpened: false,
    noticeAuthOpened: false,
    noticeUsed: false,
    systemNoticeAdded: false,
    redraw: false,
    backToFirstPage: false,
    redrawSystemNotices: false,
    notice: {},
    copiedUserNotices: '',
    addUserNoticesDialogOpened: false,
    addSystemNoticeDialogOpened: false,
    noticeAdded: false,
    deletingSystemNotices: false,
    deletingUserNotices: false,
    addingNewSystemNotice: false,
    systemNoticeEditDialogOpened: false,
    updatingSystemNotice: false,
    userNoticeEditDialogOpened: false,
    addingNewUserNotice: false,
    loadingPhoneMessages: false,
    redrawPhoneMessages: false,
    phoneMessages: [],
    totalPhoneMessages: 0,
    fetchingPhoneMessage: false,
    selectedPhoneMessage: {},
    loadingEmailMessages: false,
    redrawEmailMessages: false,
    emailMessages: [],
    totalEmailMessages: 0,
    fetchingEmailMessage: false,
    selectedEmailMessage: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_USER_NOTICES_LIST: {
            return {...state, loadingUserNotices: true, redraw: false};
        }
        case USER_NOTICES_LIST_FETCHED: {
            return {...state, loadingUserNotices: false, redraw: false, backToFirstPage: false, userNotices: action.payload.list ?? [], totalUserNotices: action.payload.totalCount};
        }
        case FETCHING_SYSTEM_NOTICES_LIST: {
            return {...state, loadingSystemNotices: true, redrawSystemNotices: false, backToFirstPage: false, };
        }
        case SYSTEM_NOTICES_LIST_FETCHED: {
            return {...state, loadingSystemNotices: false, redrawSystemNotices: false, backToFirstPage: false, systemNotices: action.payload.list ?? [], totalSystemNotices: action.payload.totalCount};
        }
        case FETCHING_NOTICE_INFO: {
            return {...state, fetchingUserNotice: true};
        }
        case NOTICE_INFO_FETCHED: {

            return {...state, fetchingUserNotice: false, selectedUserNotice: action.payload.data.notice};
        }
        case NOTICE_INFO_FAILED: {
            return {...state, fetchingUserNotice: false};

        }
        case FETCHING_SYSTEM_NOTICE_INFO: {
            return {...state, fetchingSystemNotice: true};
        }
        case SYSTEM_NOTICE_INFO_FETCHED: {

            return {...state, fetchingSystemNotice: false, selectedSystemNotice: action.payload.data.notice};
        }
        case SYSTEM_NOTICE_INFO_FAILED: {
            return {...state, fetchingSystemNotice: false};

        }

        case PANEL_USER_NOTICES_FETCHED: {
            return {...state, loadingPanelUserNotices: false, panelUserNotices: action.payload.user_notices ?? []};
        }

        case UPDATING_SYSTEM_NOTICE: {
            return {...state, updatingSystemNotice: true};
        }
        case SYSTEM_NOTICE_UPDATE_FAILED: {
            return {...state, updatingSystemNotice: false};
        }
        case SYSTEM_NOTICE_UPDATED: {
            return {...state, updatingSystemNotice: false, systemNoticeEditDialogOpened: false, redrawSystemNotices: true};
        }
        case UPDATE_NOTICES_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_NOTICE: {
            return {...state, deletingUserNotices: true};
        }
        case NOTICE_DELETE_FAILED: {
            return {...state, deletingUserNotices: false};
        }
        case NOTICE_DELETED: {
            return {...state, deletingUserNotices: false, redraw: true};
        }
        case DELETING_SYSTEM_NOTICE: {
            return {...state, deletingSystemNotices: true};
        }
        case SYSTEM_NOTICE_DELETE_FAILED: {
            return {...state, deletingSystemNotices: false};
        }
        case SYSTEM_NOTICE_DELETED: {
            return {...state, deletingSystemNotices: false, redrawSystemNotices: true};
        }
        case SWITCH_NOTICE_INFO_DIALOG: {
            return {...state, noticeInfoOpened: action.payload.state, selectedUserNotice: action.payload.data ?? {}, };
        }
        case SWITCH_SYSTEM_NOTICE_INFO_DIALOG: {
            return {...state, systemNoticeInfoOpened: action.payload.state, selectedSystemNotice: action.payload.data ?? {}, };
        }
        case SWITCH_USER_NOTICE_EDIT_DIALOG: {
            return {...state, userNoticeEditDialogOpened: action.payload.state, selectedUserNotice: action.payload.data ?? {}, };
        }
        case REDRAW_USER_NOTICES_LIST: {
            return {...state, redraw: true};
        }
        case SWITCH_ADD_NOTICE_DIALOG: {
            console.log("hey 2")
            return {...state, addUserNoticesDialogOpened: action.payload.state};
        }
        case SWITCH_ADD_SYSTEM_NOTICE_DIALOG: {
            console.log("hey 2")
            return {...state, addSystemNoticeDialogOpened: action.payload.state};
        }
        case SWITCH_SYSTEM_NOTICE_EDIT_DIALOG: {
            return {...state, systemNoticeEditDialogOpened: action.payload.state};
        }
        case NEW_NOTICE_ADDED: {
            return {...state, addingNewUserNotice: false, addUserNoticesDialogOpened: false, redraw: true, backToFirstPage: true, noticeAdded: true }
        }
        case ADDING_NEW_NOTICE : {
            return {...state, addingNewUserNotice: true}
        }
        case NEW_NOTICE_ADD_FAILED : {
            return {...state, addingNewUserNotice: false}
        }
        case ADDING_NEW_SYSTEM_NOTICE: {
            return {...state, addingNewSystemNotice: true}
        }
        case NEW_SYSTEM_NOTICE_ADDED: {
            return {...state,addingNewSystemNotice: false, addSystemNoticeDialogOpened: false, redrawSystemNotices: true, backToFirstPage: true, systemNoticeAdded: true }
        }
        case NEW_SYSTEM_NOTICE_ADD_FAILED: {
            return {...state, addingNewSystemNotice: false}
        }

        case NEW_PANEL_USER_NOTICE: {
            let panelUserNotices = state.panelUserNotices.slice();
            if(panelUserNotices.length > 0 && panelUserNotices[0].id !== action.payload.user_notice.id) {
                panelUserNotices.unshift(action.payload.user_notice);
                if(panelUserNotices.length >= 7)
                    panelUserNotices.pop();
            }

            return {...state, panelUserNotices: panelUserNotices};
        }
        case SEEN_USER_NOTICE: {
            let seenNotice = action.payload;
            let panelUserNotices = state.panelUserNotices.slice().map((notice) => {
                if(notice.id === seenNotice.notice_id)
                    notice.seen = seenNotice.seen;
                return notice;
            });
            let userNotices = state.userNotices.slice().map((notice) => {
                if(notice.id === seenNotice.notice_id)
                    notice.seen = seenNotice.seen;
                return notice;
            });
            return {...state, panelUserNotices: panelUserNotices, userNotices: userNotices};
        }



        case FETCHING_PHONE_MESSAGES_LIST: {
            return {...state, loadingPhoneMessages: true, redrawPhoneMessages: false};
        }
        case PHONE_MESSAGES_LIST_FETCHED: {
            return {...state, loadingPhoneMessages: false, redrawPhoneMessages: false, backToFirstPage: false, phoneMessages: action.payload.list ?? [], totalPhoneMessages: action.payload.totalCount};
        }
        case FETCHING_PHONE_MESSAGE: {
            return {...state, fetchingPhoneMessage: true};
        }
        case PHONE_MESSAGE_FETCHED: {

            return {...state, fetchingPhoneMessage: false, selectedPhoneMessage: action.payload.data.phoneMessage};

        }
        case PHONE_MESSAGE_FAILED: {
            return {...state, fetchingPhoneMessage: false};

        }

        case UPDATE_PHONE_MESSAGES_FILTER: {
            return {...state, redrawPhoneMessages: true, backToFirstPage: true, };
        }



        case FETCHING_EMAIL_MESSAGES_LIST: {
            return {...state, loadingEmailMessages: true, redrawEmailMessages: false};
        }
        case EMAIL_MESSAGES_LIST_FETCHED: {
            return {...state, loadingEmailMessages: false, redrawEmailMessages: false, backToFirstPage: false, emailMessages: action.payload.list ?? [], totalEmailMessages: action.payload.totalCount};
        }
        case FETCHING_EMAIL_MESSAGE: {
            return {...state, fetchingEmailMessage: true};
        }
        case EMAIL_MESSAGE_FETCHED: {

            return {...state, fetchingEmailMessage: false, selectedEmailMessage: action.payload.data.emailMessage};

        }
        case EMAIL_MESSAGE_FAILED: {
            return {...state, fetchingEmailMessage: false};

        }

        case UPDATE_EMAIL_MESSAGES_FILTER: {
            return {...state, redrawEmailMessages: true, backToFirstPage: true, };
        }


        default:


            return state;
    }
}

