import {number} from "@storybook/addon-knobs";

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_ALERT = 'show_alert';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const USER_TOKEN_EXPIRE = 'user_token_expire';
export const GET_USER_TERMINALS = 'get_user_terminals';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

//User Management [1-75]
export const USERS_LIST_FETCHED = 1;
export const FETCHING_USERS_LIST = 2;
export const REDRAW_USERS_LIST = 3;
export const USERS_LIST_FAILED = 5;
export const FETCHING_USER_INFO = 6;
export const USER_INFO_FETCHED = 7;
export const USER_INFO_FAILED = 8;
export const SWITCH_USER_INFO_DIALOG = 9;
export const SWITCH_USER_EDIT_DIALOG = 10;
export const SWITCH_USER_NOTE_DIALOG = 11;
export const SWITCH_USER_DELETE_DIALOG =12;
export const SWITCH_USER_BLOCK_DIALOG = 13;
export const SWITCH_ADD_USER_DIALOG = 14;
export const UPDATING_USER_INFO = 15;
export const USER_INFO_UPDATED = 16;
export const USER_INFO_UPDATE_FAILED = 17;
export const DELETING_USER = 18;
export const USER_DELETED = 19;
export const USER_DELETE_FAILED = 20;
export const SWITCH_USER_DOC_DIALOG = 21;
export const AUTHORIZING_DOCUMENT = 22;
export const DOCUMENT_AUTHORIZED = 23;
export const DOCUMENT_AUTHORIZATION_FAILED = 24;
export const UPDATING_USER_DOC = 25;
export const USER_DOC_UPDATED = 26;
export const USER_DOC_UPDATE_FAILED = 27;
export const UPDATE_USERS_FILTER = 28;
export const BLOCKING_USER = 29;
export const USER_BLOCKED = 30;
export const USER_BLOCK_FAILED = 31;
export const SENDING_NOTE = 32;
export const NOTE_SENT = 33;
export const NOTE_SEND_FAILED = 34;
export const ADDING_NEW_USER = 35;
export const NEW_USER_ADDED = 36;
export const NEW_USER_ADD_FAILED = 37;
export const CLEAR_USER_INFO = 38;
export const BLOCKING_BALANCE_USER = 39;
export const USER_BALANCE_BLOCKED = 40;
export const USER_BALANCE_BLOCK_FAILED = 41;
export const SWITCH_USER_BLOCK_BALANCE_DIALOG = 42;

// CreditCard Management [76-150]
export const CARDS_LIST_FETCHED = 76;
export const FETCHING_CARDS_LIST = 77;
export const REDRAW_CARDS_LIST = 78;
export const CARDS_LIST_FAILED = 79;
export const FETCHING_CARD_INFO = 80;
export const CARD_INFO_FETCHED = 81;
export const CARD_INFO_FAILED = 82;
export const SWITCH_CARD_INFO_DIALOG = 83;
export const SWITCH_CARD_EDIT_DIALOG = 84;
export const UPDATING_CARD_INFO = 85;
export const CARD_INFO_UPDATED = 86;
export const CARD_INFO_UPDATE_FAILED = 87;
export const DELETING_CARD = 88;
export const CARD_DELETED = 89;
export const CARD_DELETE_FAILED = 90;
export const SWITCH_CARD_DOC_DIALOG = 91;
export const AUTHORIZING_CARD = 92;
export const CARD_AUTHORIZED = 93;
export const CARD_AUTHORIZATION_FAILED = 94;
export const UPDATE_CARDS_FILTER = 95;
export const SWITCH_ADD_CARD_DIALOG = 96;
export const ADDING_NEW_CARD = 97;
export const NEW_CARD_ADDED = 98;
export const NEW_CARD_ADD_FAILED = 99;



// Order Management [151-225]

export const ORDERS_LIST_FETCHED = 151;
export const FETCHING_ORDERS_LIST = 152;
export const REDRAW_ORDERS_LIST = 153;
export const ORDERS_LIST_FAILED = 154;
export const FETCHING_ORDER_INFO = 155;
export const ORDER_INFO_FETCHED = 156;
export const ORDER_INFO_FAILED = 157;
export const SWITCH_ORDER_INFO_DIALOG = 158;
export const SWITCH_ORDER_EDIT_DIALOG = 159;
export const UPDATING_ORDER_INFO = 160;
export const ORDER_INFO_UPDATED = 161;
export const ORDER_INFO_UPDATE_FAILED = 162;
export const DELETING_ORDER = 163;
export const ORDER_DELETED = 164;
export const ORDER_DELETE_FAILED = 165;
export const SWITCH_ORDER_DOC_DIALOG = 166;
export const AUTHORIZING_ORDER = 167;
export const ORDER_AUTHORIZED = 168;
export const ORDER_AUTHORIZATION_FAILED = 169;
export const UPDATE_ORDERS_FILTER = 170;
export const SWITCH_WITHDRAW_DIALOG = 171;
export const PROCESSING_ORDER = 172;
export const ORDER_PROCESSED = 173;
export const ORDER_PROCESS_FAILED = 174;
export const CANCELING_ORDER = 175;
export const ORDER_CANCELED = 176;
export const ORDER_CANCEL_FAILED = 177;
export const LOCKING_ORDER = 178;
export const ORDER_LOCKED = 179;
export const ORDER_LOCK_FAILED = 180;
export const SWITCH_REQUEST_BUY_DIALOG = 181;
export const SWITCH_DEPOSIT_DIALOG = 182;
export const SWITCH_NEW_DEPOSIT_DIALOG = 183;
export const SUBMITTING_NEW_DEPOSIT = 184;
export const NEW_DEPOSIT_SUBMITTED = 185;
export const NEW_DEPOSIT_FAILED = 186;
export const SUBMITTING_NEW_BUY = 187;
export const NEW_BUY_SUBMITTED = 188;
export const NEW_SUBMIT_FAILED = 189;
export const SWITCH_REQUEST_SELL_DIALOG = 190;
export const SWITCH_BUY_DOCUMENT_DIALOG = 191;
export const SWITCH_SELL_DOCUMENT_DIALOG = 192;
export const SUBMITTING_NEW_SELL = 193;
export const NEW_SELL_SUBMITTED = 194;
export const NEW_SELL_SUBMIT_FAILED = 195;
export const SWITCH_UNFREEZE_DIALOG = 196;
export const UNFREEZING_ORDER = 197;
export const ORDER_UNFROZEN = 198;
export const ORDER_UNFREEZE_FAILED = 199;
// Transaction [226 - 300]


export const FETCHING_TRANSACTIONS_LIST =  226;
export const TRANSACTIONS_LIST_FETCHED =  227;
export const FETCHING_TRANSACTION_INFO =  228;
export const TRANSACTION_INFO_FETCHED =  229;
export const TRANSACTION_INFO_FAILED = 230;
export const UPDATING_TRANSACTION_INFO = 231;
export const TRANSACTION_INFO_UPDATE_FAILED = 232;
export const TRANSACTION_INFO_UPDATED = 233;
export const AUTHORIZING_TRANSACTION = 234;
export const TRANSACTION_AUTHORIZATION_FAILED = 235;
export const TRANSACTION_AUTHORIZED = 236;
export const UPDATE_TRANSACTIONS_FILTER = 237;
export const DELETING_TRANSACTION = 238;
export const TRANSACTION_DELETE_FAILED = 239;
export const TRANSACTION_DELETED = 240;
export const SWITCH_TRANSACTION_INFO_DIALOG = 241;
export const SWITCH_TRANSACTION_EDIT_DIALOG = 242;
export const SWITCH_TRANSACTION_DOC_DIALOG = 243;
export const REDRAW_TRANSACTIONS_LIST = 244;
export const TRANSACTIONS_LIST_FAILED = 245;
export const SWITCH_ADD_WITHDRAW_REQ_DIALOG = 246;
export const REQUESTING_NEW_WITHDRAW = 247;
export const NEW_WITHDRAW_REQUESTED = 248;
export const NEW_WITHDRAW_REQUEST_FAILED = 249;

// Gateways Management [301-425]


export const GATEWAYS_LIST_FETCHED = 301;
export const FETCHING_GATEWAYS_LIST = 302;
export const REDRAW_GATEWAYS_LIST = 303;
export const GATEWAYS_LIST_FAILED = 304;
export const FETCHING_GATEWAY_INFO = 305;
export const GATEWAY_INFO_FETCHED = 306;
export const GATEWAY_INFO_FAILED = 307;
export const SWITCH_GATEWAY_INFO_DIALOG = 308;
export const SWITCH_GATEWAY_EDIT_DIALOG = 309;
export const UPDATING_GATEWAY_INFO = 310;
export const GATEWAY_INFO_UPDATED = 311;
export const GATEWAY_INFO_UPDATE_FAILED = 312;
export const DELETING_GATEWAY = 313;
export const GATEWAY_DELETED = 314;
export const GATEWAY_DELETE_FAILED = 315;
export const SWITCH_GATEWAY_DOC_DIALOG = 316;
export const AUTHORIZING_GATEWAY = 317;
export const GATEWAY_AUTHORIZED = 318;
export const GATEWAY_AUTHORIZATION_FAILED = 319;
export const UPDATE_GATEWAYS_FILTER = 320;
export const FETCHING_BINANCE_DEPOSITS_LIST = 321;
export const BINANCE_DEPOSITS_LIST_FETCHED = 322;
export const BINANCE_DEPOSITS_LIST_FAILED = 323;
export const FETCHING_BINANCE_WITHDRAWS_LIST = 324;
export const BINANCE_WITHDRAWS_LIST_FETCHED = 325;
export const BINANCE_WITHDRAWS_LIST_FAILED = 326;
export const SWITCH_GATEWAY_BANKS_EDIT_DIALOG = 327;
export const UPDATING_GATEWAY_BANKS_INFO = 328;
export const GATEWAY_BANKS_UPDATED = 329;
export const GATEWAY_BANKS_UPDATE_FAILED = 330;

// Payments Management [426-500]


export const PAYMENTS_LIST_FETCHED = 426;
export const FETCHING_PAYMENTS_LIST = 427;
export const REDRAW_PAYMENTS_LIST = 428;
export const PAYMENTS_LIST_FAILED = 429;
export const FETCHING_PAYMENT_INFO = 430;
export const PAYMENT_INFO_FETCHED = 431;
export const PAYMENT_INFO_FAILED = 432;
export const SWITCH_PAYMENT_INFO_DIALOG = 433;
export const SWITCH_PAYMENT_EDIT_DIALOG = 434;
export const UPDATING_PAYMENT_INFO = 435;
export const PAYMENT_INFO_UPDATED = 436;
export const PAYMENT_INFO_UPDATE_FAILED = 437;
export const DELETING_PAYMENT = 438;
export const PAYMENT_DELETED = 439;
export const PAYMENT_DELETE_FAILED = 440;
export const SWITCH_PAYMENT_DOC_DIALOG = 441;
export const AUTHORIZING_PAYMENT = 442;
export const PAYMENT_AUTHORIZED = 443;
export const PAYMENT_AUTHORIZATION_FAILED = 444;
export const UPDATE_PAYMENTS_FILTER = 445;



// Ticket Management [501-575]

export const TICKETS_LIST_FETCHED = 501;
export const FETCHING_TICKETS_LIST = 502;
export const REDRAW_TICKETS_LIST = 503;
export const TICKETS_LIST_FAILED = 504;
export const FETCHING_TICKET_INFO = 505;
export const TICKET_INFO_FETCHED = 506;
export const TICKET_INFO_FAILED = 507;
export const SWITCH_TICKET_INFO_DIALOG = 508;
export const SWITCH_TICKET_EDIT_DIALOG = 509;
export const UPDATING_TICKET_INFO = 510;
export const TICKET_INFO_UPDATED = 511;
export const TICKET_INFO_UPDATE_FAILED = 512;
export const DELETING_TICKET = 513;
export const TICKET_DELETED = 514;
export const TICKET_DELETE_FAILED = 515;
export const SWITCH_TICKET_DOC_DIALOG = 516;
export const AUTHORIZING_TICKET = 517;
export const TICKET_AUTHORIZED = 518;
export const TICKET_AUTHORIZATION_FAILED = 519;
export const UPDATE_TICKETS_FILTER = 520;
export const GET_SELECTED_TICKET = 521;
export const GET_MAILS_TICKET = 522;
export const COMPOSE_TICKET =  523;
export const TICKET_DOC_UPDATE_FAILED = 524;
export const UPDATING_TICKET_DOC = 525;
export const TICKET_MESSAGE_SENDING = 526;
export const TICKET_MESSAGE_SENT = 527;
export const TICKET_MESSAGE_SENDING_FAILED = 528;
export const NEW_TICKET_SENDING = 529;
export const NEW_TICKET_SENT = 530;
export const NEW_TICKET_SENDING_FAILED = 531;
export const CLOSING_TICKET = 532;
export const TICKET_CLOSED = 533;
export const TICKET_CLOSE_FAILED = 534;
export const TICKET_REPLY_SENT = 535;
export const COMPOSE_SENT = 536;
export const SWITCH_DELETE_TICKET_CONFIRM_DIALOG = 537;
export const TICKET_SEEN = 538;
export const TICKET_TYPING = 539;
export const NEW_TICKET = 540;
export const NEW_TICKET_MESSAGE = 541;


// Setting Management [576-650]

export const SETTINGS_LIST_FETCHED = 576;
export const FETCHING_SETTINGS_LIST = 577;
export const REDRAW_SETTINGS_LIST = 578;
export const SETTINGS_LIST_FAILED = 579;
export const FETCHING_SETTING_INFO = 580;
export const SETTING_INFO_FETCHED = 581;
export const SETTING_INFO_FAILED = 582;
export const SWITCH_SETTING_INFO_DIALOG = 583;
export const SWITCH_SETTING_EDIT_DIALOG = 584;
export const UPDATING_SETTING_INFO = 585;
export const SETTING_INFO_UPDATED = 586;
export const SETTING_INFO_UPDATE_FAILED = 587;
export const DELETING_SETTING = 588;
export const SETTING_DELETED = 589;
export const SETTING_DELETE_FAILED = 590;
export const SWITCH_SETTING_DOC_DIALOG = 591;
export const AUTHORIZING_SETTING = 592;
export const SETTING_AUTHORIZED = 593;
export const SETTING_AUTHORIZATION_FAILED = 594;
export const UPDATE_SETTINGS_FILTER = 595;



//Supply Setting Management [651-725]

export const SUPPLY_SETTINGS_LIST_FETCHED = 651;
export const FETCHING_SUPPLY_SETTINGS_LIST = 652;
export const REDRAW_SUPPLY_SETTINGS_LIST = 653;
export const SUPPLY_SETTINGS_LIST_FAILED = 654;
export const FETCHING_SUPPLY_SETTING_INFO = 656;
export const SUPPLY_SETTING_INFO_FETCHED = 657;
export const SUPPLY_SETTING_INFO_FAILED = 658;
export const SWITCH_SUPPLY_SETTING_INFO_DIALOG = 659;
export const SWITCH_SUPPLY_SETTING_EDIT_DIALOG = 660;
export const UPDATING_SUPPLY_SETTING_INFO = 661;
export const SUPPLY_SETTING_INFO_UPDATED = 662;
export const SUPPLY_SETTING_INFO_UPDATE_FAILED = 663;
export const DELETING_SUPPLY_SETTING = 664;
export const SUPPLY_SETTING_DELETED = 665;
export const SUPPLY_SETTING_DELETE_FAILED = 666;
export const SWITCH_SUPPLY_SETTING_DOC_DIALOG = 667;
export const AUTHORIZING_SUPPLY_SETTING = 668;
export const SUPPLY_SETTING_AUTHORIZED = 669;
export const SUPPLY_SETTING_AUTHORIZATION_FAILED = 670;
export const UPDATE_SUPPLY_SETTINGS_FILTER = 671;
export const FETCHING_SPECIAL_SUPPLIES_LIST = 672;
export const SPECIAL_SUPPLIES_LIST_FETCHED = 673;
export const SPECIAL_SUPPLIES_LIST_FAILED = 674;
export const FETCHING_SPECIAL_SUPPLY_INFO = 675;
export const SPECIAL_SUPPLY_INFO_FETCHED = 676;
export const SPECIAL_SUPPLY_INFO_FAILED = 677;
export const FETCHING_SUPPLY_TRANSACTIONS_LIST = 678;
export const SUPPLY_TRANSACTIONS_LIST_FETCHED = 679;
export const SUPPLY_TRANSACTIONS_LIST_FAILED = 680;
export const FETCHING_SUPPLIES_LIST = 681;
export const SUPPLIES_LIST_FETCHED = 682;
export const SUPPLIES_LIST_FAILED = 683;
export const SWITCH_SUPPLIES_EDIT_DIALOG = 684;
export const UPDATING_SUPPLIES_INFO = 685;
export const SUPPLIES_INFO_UPDATED = 686;
export const SUPPLIES_INFO_UPDATE_FAILED = 687;

// AUTHENTICATION Management [726-800]

export const AUTHENTICATIONS_LIST_FETCHED = 726;
export const FETCHING_AUTHENTICATIONS_LIST = 727;
export const REDRAW_AUTHENTICATIONS_LIST = 728;
export const AUTHENTICATIONS_LIST_FAILED = 729;
export const FETCHING_AUTHENTICATION_INFO = 730;
export const AUTHENTICATION_INFO_FETCHED = 731;
export const AUTHENTICATION_INFO_FAILED = 732;
export const SWITCH_AUTHENTICATION_INFO_DIALOG = 733;
export const SWITCH_AUTHENTICATION_EDIT_DIALOG = 734;
export const UPDATING_AUTHENTICATION_INFO = 735;
export const AUTHENTICATION_INFO_UPDATED = 736;
export const AUTHENTICATION_INFO_UPDATE_FAILED = 737;
export const DELETING_AUTHENTICATION = 738;
export const AUTHENTICATION_DELETED = 739;
export const AUTHENTICATION_DELETE_FAILED = 740;
export const SWITCH_AUTHENTICATION_DOC_DIALOG = 741;
export const AUTHORIZING_AUTHENTICATION = 742;
export const AUTHENTICATION_AUTHORIZED = 743;
export const AUTHENTICATION_AUTHORIZATION_FAILED = 744;
export const UPDATE_AUTHENTICATIONS_FILTER = 745;
export const AUTHENTICATION_DOC_UPDATED = 746;
export const UPDATING_AUTHENTICATION_DOC = 747;
export const AUTHENTICATION_DOC_UPDATE_FAILED = 748;
export const SWITCH_ADD_DOCUMENT_DIALOG = 749;
export const ADDING_NEW_DOCUMENT = 750;
export const NEW_DOCUMENT_ADDED = 751;
export const NEW_DOCUMENT_ADD_FAILED = 752;



// BLACKLIST Management [801-875]

export const BLACKLISTS_LIST_FETCHED = 801;
export const FETCHING_BLACKLISTS_LIST = 802;
export const REDRAW_BLACKLISTS_LIST = 803;
export const BLACKLISTS_LIST_FAILED = 804;
export const FETCHING_BLACKLIST_INFO = 805;
export const BLACKLIST_INFO_FETCHED = 806;
export const BLACKLIST_INFO_FAILED = 807;
export const SWITCH_BLACKLIST_INFO_DIALOG = 808;
export const SWITCH_BLACKLIST_EDIT_DIALOG = 809;
export const UPDATING_BLACKLIST_INFO = 810;
export const BLACKLIST_INFO_UPDATED = 811;
export const BLACKLIST_INFO_UPDATE_FAILED = 812;
export const DELETING_BLACKLIST = 813;
export const BLACKLIST_DELETED = 814;
export const BLACKLIST_DELETE_FAILED = 815;
export const SWITCH_BLACKLIST_DOC_DIALOG = 816;
export const AUTHORIZING_BLACKLIST = 817;
export const BLACKLIST_AUTHORIZED = 818;
export const BLACKLIST_AUTHORIZATION_FAILED = 819;
export const UPDATE_BLACKLISTS_FILTER = 820;
export const ADDING_BLACKLIST = 821;
export const BLACKLIST_ADDED = 822;
export const BLACKLIST_ADD_FAILED = 823;


//Currency [876-950]

export const CURRENCIES_FETCHED = 876;
export const SUPPLIES_FETCHED = 878;
export const FETCHING_NETWORKS_LIST = 879;
export const NETWORKS_LIST_FETCHED = 880;
export const NETWORKS_LIST_FAILED = 881;
export const PRICE_UPDATE_SETTINGS_FETCHED = 882;
export const FETCHING_NETWORKS = 883;
export const UPDATE_NETWORK = 884;
export const SWITCH_ADD_CURRENCY_DIALOG = 885;
export const FETCHING_CURRENCIES_LIST = 886;
export const CURRENCIES_LIST_FETCHED = 887;
export const CURRENCIES_LIST_FAILED = 888;
export const FETCHING_CURRENCY_INFO = 889;
export const CURRENCY_INFO_FETCHED = 890;
export const CURRENCY_INFO_FAILED = 891;
export const AVAIL_CURRENCIES_FETCHED = 892;
export const BASE_CURRENCIES_FETCHED = 893;
export const ADDING_NEW_CURRENCY = 894;
export const NEW_CURRENCY_ADDED = 895;
export const NEW_CURRENCY_ADD_FAILED = 896;
export const FETCHING_TERMINAL_CURRENCY_INFO = 897;
export const TERMINAL_CURRENCY_INFO_FETCHED = 898;
export const TERMINAL_CURRENCY_INFO_FAILED = 899;
export const UPDATING_TERMINAL_CURRENCY_INFO = 900;
export const TERMINAL_CURRENCY_INFO_UPDATED = 901;
export const TERMINAL_CURRENCY_INFO_UPDATE_FAILED = 902;
export const SWITCH_TERMINAL_CURRENCY_EDIT_DIALOG = 903;
export const CHECKING_SOCKET = 904;
export const SOCKET_CHECKED = 905;
export const SOCKET_CHECK_FAILED = 906;
export const STOPPING_SOCKET = 907;
export const SOCKET_STOPPED = 908;
export const SOCKET_STOP_FAILED = 909;
export const STARTING_SOCKET = 910;
export const SOCKET_STARTED = 911;
export const SOCKET_START_FAILED = 912;
export const SWITCH_NETWORK_INFO_DIALOG = 913;
export const FETCHING_NETWORK_INFO = 914;
export const NETWORK_INFO_FETCHED = 915;
export const NETWORK_INFO_FAILED = 916;
export const SWITCH_TERMINAL = 917;

// Inquiry Management [951-1025]

export const SWITCH_INQUIRY_DIALOG = 951;
export const PROCESSING_INQUIRY = 952;
export const INQUIRY_PROCESSED = 953;
export const INQUIRY_PROCESS_FAILED = 954;


//Draft Management [1026-1100]


export const UPDATING_DRAFT_INFO = 1026;
export const DRAFT_INFO_UPDATED = 1027;
export const DRAFT_INFO_UPDATE_FAILED = 1028;
export const REDRAW_DRAFTS_LIST = 1029;
export const DELETING_DRAFT = 1030;
export const DRAFT_DELETED = 1031;
export const DRAFT_DELETE_FAILED = 1032;
export const FETCHING_DRAFT_INFO = 1033;
export const DRAFT_INFO_FETCHED = 1034;
export const DRAFT_INFO_FAILED = 1035;
export const FETCHING_DRAFTS_LIST = 1036;
export const DRAFTS_LIST_FETCHED = 1037;
export const DRAFTS_LIST_FAILED = 1038;
export const UPDATE_DRAFTS_FILTER = 1039;
export const SWITCH_DRAFT_INFO_DIALOG = 1040;
export const SWITCH_DRAFT_EDIT_DIALOG = 1041;
export const SWITCH_ADD_DRAFT_DIALOG = 1042;
export const ADDING_NEW_DRAFT = 1043;
export const NEW_DRAFT_ADDED = 1044;
export const NEW_DRAFT_ADD_FAILED = 1045;
export const SWITCH_DRAFT_DOC_DIALOG = 1046;
export const AUTHORIZING_DRAFT = 1047;
export const DRAFT_AUTHORIZED = 1048;
export const DRAFT_AUTHORIZATION_FAILED = 1049;
export const DRAFT_COPIED = 1050;


//Log Management [1101-1175]

export const LOGS_LIST_FETCHED = 1101;
export const FETCHING_LOGS_LIST = 1102;
export const REDRAW_LOGS_LIST = 1103;
export const LOGS_LIST_FAILED = 1104;
export const FETCHING_LOG_INFO = 1105;
export const LOG_INFO_FETCHED = 1106;
export const LOG_INFO_FAILED = 1107;
export const UPDATING_LOG_INFO = 1108;
export const LOG_INFO_UPDATED = 1109;
export const LOG_INFO_UPDATE_FAILED = 1110;
export const DELETING_LOG = 1111;
export const LOG_DELETED = 1112;
export const LOG_DELETE_FAILED = 1113;
export const UPDATE_LOGS_FILTER = 1114;


//Profit Management [1176-1250]

export const FETCHING_PROFITS_LIST = 1176;
export const PROFITS_LIST_FETCHED = 1177;
export const PROFITS_LIST_FAILED = 1178;
export const FETCHING_SIMPLE_STATS = 1179;
export const SIMPLE_STATS_FETCHED = 1180;
export const SIMPLE_STATS_FETCH_FAILED = 1181;
export const UPDATE_PROFITS_FILTER = 1182;
export const FETCHING_CURRENCY_PROFITS_LIST = 1183;
export const CURRENCY_PROFITS_LIST_FETCHED = 1184;
export const CURRENCY_PROFITS_LIST_FAILED = 1185;
export const FETCHING_WHOLE_CURRENCY_PROFITS_LIST = 1186;
export const WHOLE_CURRENCY_PROFITS_LIST_FETCHED = 1187;
export const WHOLE_CURRENCY_PROFITS_LIST_FAILED = 1188;
export const FETCHING_PROFIT_STATS = 1189;
export const PROFIT_STATS_FETCHED = 1190;
export const PROFIT_STATS_FETCH_FAILED = 1191;
export const FETCHING_LOSSES_LIST = 1192;
export const LOSSES_LIST_FETCHED = 1193;
export const FETCHING_ALL_PROFITS_LIST = 1194;
export const ALL_PROFITS_LIST_FETCHED = 1195;
export const UPDATE_DAILY_PROFITS_FILTER = 1196;
export const UPDATE_COSTS_FILTER = 1197;
export const UPDATE_ALL_PROFITS_FILTER = 1198;
//Lang [1251-1325]

export const TRANSLATES_FETCHED = 1251;

//Notifications [1326-1400]
export const FETCHING_USER_NOTICES_LIST = 1326;
export const USER_NOTICES_LIST_FETCHED = 1327;
export const USER_NOTICES_LIST_FAILED = 1328;
export const FETCHING_NOTICE_INFO = 1329;
export const NOTICE_INFO_FETCHED = 1330;
export const NOTICE_INFO_FAILED = 1331;
export const PANEL_USER_NOTICES_FETCHED = 1332;
export const REDRAW_USER_NOTICES_LIST = 1333;
export const SWITCH_NOTICE_INFO_DIALOG = 1334;
export const SWITCH_ADD_NOTICE_DIALOG = 1335;
export const ADDING_NEW_NOTICE = 1336;
export const NEW_NOTICE_ADDED = 1337;
export const NEW_NOTICE_ADD_FAILED = 1338;
export const DELETING_NOTICE = 1339;
export const NOTICE_DELETED = 1340;
export const NOTICE_DELETE_FAILED = 1341;
export const NEW_PANEL_USER_NOTICE = 1342;
export const SEEN_USER_NOTICE = 1343;
export const UPDATE_NOTICES_FILTER = 1344;
export const FETCHING_SYSTEM_NOTICES_LIST = 1345;
export const SYSTEM_NOTICES_LIST_FETCHED = 1346;
export const SYSTEM_NOTICES_LIST_FAILED = 1347;
export const FETCHING_SYSTEM_NOTICE_INFO = 1348;
export const SYSTEM_NOTICE_INFO_FETCHED = 1349;
export const SYSTEM_NOTICE_INFO_FAILED = 1350;
export const SWITCH_ADD_SYSTEM_NOTICE_DIALOG = 1351;
export const ADDING_NEW_SYSTEM_NOTICE = 1352;
export const NEW_SYSTEM_NOTICE_ADDED = 1353;
export const NEW_SYSTEM_NOTICE_ADD_FAILED = 1354;
export const DELETING_SYSTEM_NOTICE = 1355;
export const SYSTEM_NOTICE_DELETED = 1356;
export const SYSTEM_NOTICE_DELETE_FAILED = 1357;
export const SWITCH_SYSTEM_NOTICE_INFO_DIALOG = 1358;
export const UPDATE_SYSTEM_NOTICES_FILTER = 1359 ;
export const SWITCH_SYSTEM_NOTICE_EDIT_DIALOG = 1360;
export const UPDATING_SYSTEM_NOTICE = 1361;
export const SYSTEM_NOTICE_UPDATED = 1362;
export const SYSTEM_NOTICE_UPDATE_FAILED = 1363;
export const SWITCH_USER_NOTICE_EDIT_DIALOG = 1364;
export const UPDATING_USER_NOTICE = 1365;
export const USER_NOTICE_UPDATED = 1366;
export const USER_NOTICE_UPDATE_FAILED = 1367;
export const EMAIL_MESSAGE_FAILED = 1368;
export const EMAIL_MESSAGE_FETCHED = 1369;
export const EMAIL_MESSAGES_LIST_FAILED = 1370;
export const EMAIL_MESSAGES_LIST_FETCHED = 1371;
export const FETCHING_EMAIL_MESSAGE = 1372;
export const FETCHING_EMAIL_MESSAGES_LIST = 1373;
export const FETCHING_PHONE_MESSAGE = 1374;
export const FETCHING_PHONE_MESSAGES_LIST = 1375;
export const PHONE_MESSAGE_FAILED = 1376;
export const PHONE_MESSAGE_FETCHED = 1377;
export const PHONE_MESSAGES_LIST_FAILED = 1378;
export const PHONE_MESSAGES_LIST_FETCHED = 1379;
export const UPDATE_EMAIL_MESSAGES_FILTER = 1380;
export const UPDATE_PHONE_MESSAGES_FILTER = 1381;

//Affiliate [1401-1475]

export const FETCHING_MASTER_PROFITS_LIST = 1401;
export const MASTER_PROFITS_LIST_FETCHED = 1402;
export const MASTER_PROFITS_LIST_FAILED = 1403;
export const FETCHING_DAILY_PROFITS_LIST = 1404;
export const DAILY_PROFITS_LIST_FETCHED = 1405;
export const DAILY_PROFITS_LIST_FAILED = 1406;
export const FETCHING_DAILY_PROFIT_DETAIL_LIST = 1407;
export const DAILY_PROFIT_DETAIL_LIST_FETCHED = 1408;
export const DAILY_PROFIT_DETAIL_LIST_FAILED = 1409;
export const DETAIL_INFO_EXPANDED = 1410;
export const FETCHING_MASTERS_DAILY_PROFIT_LIST = 1411;
export const MASTERS_DAILY_PROFIT_LIST_FETCHED = 1412;
export const MASTERS_DAILY_PROFIT_LIST_FAILED = 1413;
export const FETCHING_MASTERS_DAILY_PROFITS_LIST = 1414;
export const MASTERS_DAILY_PROFITS_LIST_FETCHED = 1415;
export const MASTERS_DAILY_PROFITS_LIST_FAILED = 1416;
export const FETCHING_MASTERS_PROFIT_DETAIL_LIST = 1417;
export const MASTERS_PROFIT_DETAIL_LIST_FETCHED = 1418;
export const MASTERS_PROFIT_DETAIL_LIST_FAILED = 1419;

//Prize [1476-1550]
export const UPDATING_PRIZE_INFO = 1476;
export const PRIZE_INFO_UPDATED = 1477;
export const PRIZE_INFO_UPDATE_FAILED = 1478;
export const FETCHING_PRIZE_INFO = 1479;
export const PRIZE_INFO_FETCHED = 1480;
export const PRIZE_INFO_FAILED = 1481;
export const FETCHING_PRIZES_LIST = 1482;
export const PRIZES_LIST_FETCHED = 1483;
export const PRIZES_LIST_FAILED = 1484;
export const UPDATE_PRIZES_FILTER = 1485;
export const SWITCH_PRIZE_INFO_DIALOG = 1486;
export const SWITCH_PRIZE_EDIT_DIALOG = 1487;
export const REDRAW_PRIZES_LIST = 1488;


//Terminal [1551-1625]
export const FETCHING_TERMINAL_INFOS = 1551;
export const TERMINAL_INFOS_FETCHED = 1552;
export const TERMINAL_INFOS_FAILED = 1553;
export const UPDATING_TERMINAL_INFO = 1554;
export const TERMINAL_INFO_UPDATED = 1555;
export const TERMINAL_INFO_UPDATE_FAIL = 1556;
export const DELETING_ADMIN = 1557;
export const ADMIN_DELETED = 1558;
export const ADMIN_DELETE_FAILED = 1559;
export const UPDATING_ADMIN_INFO = 1560;
export const ADMIN_INFO_UPDATED = 1561;
export const ADMIN_INFO_UPDATE_FAILED = 1562;
export const FETCHING_ADMIN_INFO = 1563;
export const ADMIN_INFO_FETCHED = 1564;
export const ADMIN_INFO_FAILED = 1565;
export const ADDING_NEW_ADMIN = 1566;
export const NEW_ADMIN_ADDED = 1567;
export const NEW_ADMIN_ADD_FAILED = 1568;
export const FETCHING_TERMINAL_ADMINS_LIST = 1569;
export const TERMINAL_ADMINS_LIST_FETCHED = 1570;
export const TERMINAL_ADMINS_LIST_FAILED = 1571;
export const UPDATE_TERMINAL_ADMINS_FILTER = 1572;
export const SWITCH_ADMIN_INFO_DIALOG = 1573;
export const SWITCH_ADMIN_EDIT_DIALOG = 1574;
export const SWITCH_ADD_ADMIN_DIALOG = 1575;
export const UPDATING_TICKET_SIGNATURE = 1576;
export const TICKET_SIGNATURE_UPDATED = 1577;
export const TICKET_SIGNATURE_UPDATE_FAILED = 1578;
export const SWITCH_USERS_DIALOG = 1579;
export const FETCHING_FROZEN_USERS_LIST = 1580;
export const FROZEN_USERS_LIST_FETCHED = 1581;
export const FROZEN_USERS_LIST_FAILED = 1582;
export const UPDATING_FROZEN_USERS = 1583;
export const FROZEN_USERS_UPDATED = 1584;
export const FROZEN_USERS_UPDATE_FAILED = 1585;
export const FETCHING_LEVELS_INFOS = 1586;
export const LEVELS_INFOS_FETCHED = 1587;
export const UPDATING_LEVEL_INFO = 1588;
export const LEVEL_INFO_UPDATE_FAIL = 1589;
export const LEVEL_INFO_UPDATED = 1590;
export const LEVELS_INFOS_FAILED = 1591;
export const APPLY_FROZEN_USERS_FILTER = 1592;

//Layout [1626-1700]
export const WINDOW_WIDTH = 1626;

//Discount [1701-1775]
export const UPDATING_DISCOUNT_INFO = 1701;
export const DISCOUNT_INFO_UPDATED = 1702;
export const DISCOUNT_INFO_UPDATE_FAILED = 1703;
export const REDRAW_DISCOUNTS_LIST = 1704;
export const DELETING_DISCOUNT = 1705;
export const DISCOUNT_DELETED = 1706;
export const DISCOUNT_DELETE_FAILED = 1707;
export const FETCHING_DISCOUNT_INFO = 1708;
export const DISCOUNT_INFO_FETCHED = 1709;
export const DISCOUNT_INFO_FAILED = 1710;
export const FETCHING_DISCOUNTS_LIST = 1711;
export const DISCOUNTS_LIST_FETCHED = 1712;
export const DISCOUNTS_LIST_FAILED = 1713;
export const UPDATE_DISCOUNTS_FILTER = 1714;
export const SWITCH_DISCOUNT_INFO_DIALOG = 1715;
export const SWITCH_DISCOUNT_EDIT_DIALOG = 1716;
export const SWITCH_ADD_DISCOUNT_DIALOG = 1717;
export const ADDING_NEW_DISCOUNT = 1718;
export const NEW_DISCOUNT_ADDED = 1719;
export const NEW_DISCOUNT_ADD_FAILED = 1720;
export const FETCHING_USER_DISCOUNTS = 1721;
export const USER_DISCOUNTS_FETCHED = 1722;
export const USER_DISCOUNTS_FETCH_FAILED = 1723;

//Wallet [1776-1850]

export const FETCHING_WALLET_LIST = 1776;
export const WALLET_LIST_FETCHED = 1777;
export const WALLET_LIST_FAILED = 1778;
export const UPDATING_WALLET_INFO = 1779;
export const WALLET_INFO_UPDATED = 1780;
export const WALLET_INFO_UPDATE_FAILED = 1781;
export const FETCHING_WALLET_INFO = 1782;
export const WALLET_INFO_FETCHED = 1783;
export const WALLET_INFO_FAILED = 1784;
export const SWITCH_WALLET_EDIT_DIALOG = 1785;
export const SWITCH_WALLET_INFO_DIALOG = 1786;
export const SWITCH_WALLET_BLOCK_BALANCE_DIALOG = 1787;
export const BLOCKING_BALANCE_WALLET = 1788;
export const WALLET_BALANCE_BLOCKED = 1789;
export const WALLET_BALANCE_BLOCK_FAILED = 1790;
export const REDRAW_WALLETS_LIST = 1791;
export const UPDATE_WALLETS_FILTER = 1792;
export const FETCHING_WALLETS_STATS = 1793;
export const WALLETS_STATS_FETCHED = 1794;


//Favorite addresses [1850-1925]

export const FETCHING_FAV_ADDRESS_LIST = 1850;
export const FAV_ADDRESS_LIST_FETCHED = 1851;
export const FAV_ADDRESS_LIST_FAILED = 1852;
export const UPDATING_FAV_ADDRESS_INFO = 1853;
export const FAV_ADDRESS_UPDATED = 1854;
export const FAV_ADDRESS_UPDATE_FAILED = 1855;
export const FETCHING_FAV_ADDRESS_INFO = 1856;
export const FAV_ADDRESS_INFO_FETCHED = 1857;
export const FAV_ADDRESS_INFO_FAILED = 1858;
export const SWITCH_FAV_ADDRESS_EDIT_DIALOG = 1859;
export const SWITCH_FAV_ADDRESS_INFO_DIALOG = 1860;
export const REDRAW_FAV_ADDRESS_LIST = 1861;
export const UPDATE_FAV_ADDRESS_FILTER = 1862;
export const ADDING_NEW_FAV_ADDRESS = 1863;
export const NEW_FAV_ADDRESS_ADD_FAILED = 1864;
export const NEW_FAV_ADDRESS_ADDED = 1865;
export const SWITCH_ADD_FAV_ADDRESS_DIALOG = 1866;
export const SWITCH_DELETE_FAV_ADDRESS_DIALOG = 1867;
export const DELETING_FAV_ADDRESS = 1868;
export const FAV_ADDRESS_DELETE_FAILED = 1869;
export const FAV_ADDRESS_DELETED = 1870;


